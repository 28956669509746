// Scss that this module works with
// .responsiveVideo {
//     position: relative;
//     width: 100%;
//     max-width: 100%;
//     height: 0;
//     padding-bottom: 62%; // roughly a youtube video ratio
// 
//     iframe,
//     video {
//         height: 100%;
//         width: 100%;
//         max-width: 100%;
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//     }
// }

/**
 * Adds "responsiveVideo" class to the parent elements of iframe and video elements
 * Optionally restricted by container element
 *
 * @deps jquery
 * @param string containerString query string to restrict the effects within
 */

function addResponsiveVideoClasses(containerString) {
    if (containerString && containerString != '' && $(containerString).length) {
        $(containerString).find('iframe').parent().addClass('responsiveVideo');
        $(containerString).find('video').parent().addClass('responsiveVideo');
    }
}

export { addResponsiveVideoClasses }